import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import VerticalBarChart from '../../shared/charts/VerticalBarChart'
import {
  chartColors,
  costsChartOptions,
} from '../../../helper/charts/charts.options'
import { getMonthLabelsWithCapitalLetters } from '../../../helper/dates'
import moment from 'moment'
import {
  handleCostTrackingData,
  handleCostTrackingStackedData,
  filterByYear,
  transformToCostTrackingDataset,
  getChartDataset,
} from '../../../helper/charts/charts.helper'
import MultiSelect from '../../shared/form/MultiSelect'
import Tag from '../../shared/Tag'
import { useCostsPageContext } from '../../../contextproviders/CostsPageContext'
import CostsTable from './CostsTable'
import { MultiCheckbox } from 'components/shared/form/MultiCheckbox'

function CostsChart() {
  const { t } = useTranslation()
  const { costs } = useCostsPageContext()
  const currentYear = moment().year()

  const monthLabels = getMonthLabelsWithCapitalLetters()

  const [selectedServices, setSelectedServices] = useState([])
  const [selectedBilling, setSelectedBilling] = useState([])
  const [costsChartDataset, setCostsChartDataset] = useState([])
  const [currentYearCostsSum, setCurrentYearCostsSum] = useState(0)
  const getOptionsFromData = (data, fieldName) => {
    const uniques = [...new Set(data.map((item) => item[fieldName]))]
    const options = []

    uniques.forEach((unique) => [
      options.push({ value: unique, label: t(`${unique}`) }),
    ])

    return options
  }

  const dataCostsSummary = (data) =>
    'costs_summary' in data ? data.costs_summary : []

  const AVAILABLE_YEARS = [currentYear - 2, currentYear - 1, currentYear]
  const yearFilteredData = dataCostsSummary(costs.data).filter((obj) =>
    AVAILABLE_YEARS.includes(obj.year)
  )

  const serviceOptions = useMemo(
    () => getOptionsFromData(yearFilteredData, 'service'),
    [costs.data]
  )
  const billingTypeOptions = useMemo(
    () => getOptionsFromData(yearFilteredData, 'type'),
    [costs.data]
  )
  const handleSelectedServiceChange = (selectedOptions) => {
    setSelectedServices(selectedOptions)
  }

  const handleSelectedBillingChange = (selectedOptions) => {
    setSelectedBilling(selectedOptions)
  }

  const handleDeleteServiceClick = (value) => {
    setSelectedServices((services) => services.filter((s) => s.value !== value))
  }

  const handleDeleteBillingTypeClick = (value) => {
    setSelectedBilling((billing) => billing.filter((s) => s.value !== value))
  }

  const filterDataByServices = (data = [], services) => {
    return data.filter((d) => services.includes(d.service))
  }

  const filterDataByBillingTypes = (data = [], types) => {
    return data.filter((d) => types.includes(d.type))
  }

  const setChartData = (costsData) => {
    if (!costsData) return null

    if (selectedBilling.length > 0) {
      const billingTypes = selectedBilling
      const data = handleCostTrackingStackedData(costsData, billingTypes)
      const dataset = data.map((d, i) =>
        transformToCostTrackingDataset(
          d.data,
          t(d.label),
          d.backgroundColor,
          d.stack
        )
      )
      setCostsChartDataset(dataset)
    } else {
      const data = handleCostTrackingData(costsData)

      const dataset = data.map((d, i) =>
        transformToCostTrackingDataset(
          d.data,
          t(d.label),
          chartColors[i].default
        )
      )

      setCostsChartDataset(dataset)
    }
  }

  // filter chart data and count sum when selection on service or billing changes
  useEffect(() => {
    let data
    let sum = 0

    if (selectedServices.length > 0) {
      data = filterDataByServices(
        yearFilteredData,
        selectedServices.map((service) => service.value)
      )
    } else {
      data = []
    }

    if (selectedBilling.length > 0) {
      data = filterDataByBillingTypes(
        data,
        selectedBilling.map((type) => type.value)
      )
    } else {
      data = []
    }

    const currentYearData = filterByYear(data, currentYear)
    currentYearData.forEach((d) => {
      d.costs.forEach((month) => {
        sum += month.cost
      })
    })

    setCurrentYearCostsSum(Math.round(sum))
    setChartData(data)
  }, [selectedServices, selectedBilling])

  // set all service and billing types as selected by default
  // cleanup filters and sum when data changes
  useEffect(() => {
    setSelectedServices(serviceOptions)
    setSelectedBilling(billingTypeOptions)
    return () => {
      setSelectedServices([])
      setSelectedBilling([])
      setCurrentYearCostsSum(0)
    }
  }, [costs])

  const noResults = () => {
    return <p className="has-text-centered">{t('No results.')}</p>
  }

  const getCostsChart = () => {
    if (costsChartDataset.length) {
      return (
        <VerticalBarChart
          data={getChartDataset(monthLabels, costsChartDataset)}
          options={costsChartOptions}
        />
      )
    } else return noResults()
  }

  const customBillingSort = (a, b) => {
    const priority = {
      Sopimustyö: 1,
      Lisätyö: 2,
      Tarvikkeet: 3,
    }

    const aPriority = priority[a.label] || Infinity // Default to Infinity if not in priority list
    const bPriority = priority[b.label] || Infinity

    // Compare based on priority
    return aPriority - bPriority
  }

  return (
    <>
      <nav className="level mb-2">
        <div className="level-left">
          <div className="level-item">
            <h2 className="title is-size-4">{t('Cost tracking')}</h2>
          </div>
        </div>
      </nav>

      <div className="content">
        <div className="columns">
          <div className="column is-one-third">
            <MultiCheckbox
              label={'Palvelut'}
              options={serviceOptions}
              onChange={handleSelectedServiceChange}
              handleDelete={handleDeleteServiceClick}
            />
          </div>
          <div className="column is-one-third">
            <MultiCheckbox
              label={'Laskutustyyppi'}
              options={billingTypeOptions.sort(customBillingSort)}
              onChange={handleSelectedBillingChange}
              handleDelete={handleDeleteBillingTypeClick}
            />
          </div>
        </div>
        {getCostsChart()}
      </div>
    </>
  )
}

export default CostsChart
