import React, { useState, useEffect, useMemo } from 'react'
import SortableTable from '../../shared/SortableTable'
import { useTranslation } from 'react-i18next'
import 'moment/locale/fi'
import { useCostsPageContext } from 'contextproviders/CostsPageContext'
import { YearSelection } from 'components/shared/YearSelection'
import { MultiCheckbox } from 'components/shared/form/MultiCheckbox'
import moment from 'moment'

const CostsTable = () => {
  const currentYear = moment().year()
  const AVAILABLE_YEARS = [currentYear - 2, currentYear - 1, currentYear]
  const { t } = useTranslation()
  const { costs } = useCostsPageContext()
  const costsDataBySite = (data) =>
    'costsBySite' in data ? data.costsBySite : []

  const [chosenYear, setChosenYear] = useState(null)
  const [selectedServices, setSelectedServices] = useState([])
  const [selectedBilling, setSelectedBilling] = useState([])
  const years = costsDataBySite(costs.data).reduce((acc, site) => {
    const yearsBySite = site.reports.reduce(
      (acc, report) =>
        acc.includes(report.year) ? acc : [...acc, report.year],
      []
    )
    const newYears = yearsBySite.filter(
      (year) => !acc.includes(year) && AVAILABLE_YEARS.includes(year)
    )
    return [...acc, ...newYears]
  }, [])

  useEffect(() => {
    setChosenYear(years.length > 0 ? years.sort()[years.length - 1] : null)
    setSelectedServices(serviceOptions)
    setSelectedBilling(billingTypeOptions)
    return () => {
      setSelectedServices([])
      setSelectedBilling([])
    }
  }, [costs])

  const siteCustomSort = (a, b) => {
    // Compare cities first
    if (a.siteDetails.address.city < b.siteDetails.address.city) {
      return -1
    }
    if (a.siteDetails.address.city > b.siteDetails.address.city) {
      return 1
    }

    // If cities are the same, compare addresses
    if (a.siteDetails.address.street_name < b.siteDetails.address.street_name) {
      return -1
    }
    if (a.siteDetails.address.street_name > b.siteDetails.address.street_name) {
      return 1
    }

    // If both city and address are the same
    return 0
  }

  const getOptionsFromData = (data, fieldName) => {
    const uniques = [...new Set(data.map((item) => item[fieldName]))]
    const options = []

    uniques.forEach((unique) => [
      options.push({ value: unique, label: t(`${unique}`) }),
    ])

    return options
  }
  const dataCostsSummary = (data) =>
    'costs_summary' in data ? data.costs_summary : []

  const serviceOptions = useMemo(
    () => getOptionsFromData(dataCostsSummary(costs.data), 'service'),
    [costs.data]
  )

  const billingTypeOptions = useMemo(
    () => getOptionsFromData(dataCostsSummary(costs.data), 'type'),
    [costs.data]
  )

  const handleSelectedServiceChange = (selectedOptions) => {
    setSelectedServices(selectedOptions)
  }

  const handleSelectedBillingChange = (selectedOptions) => {
    setSelectedBilling(selectedOptions)
  }

  const handleDeleteServiceClick = (value) => {
    setSelectedServices((services) => services.filter((s) => s.value !== value))
  }

  const handleDeleteBillingTypeClick = (value) => {
    setSelectedBilling((billingTypes) =>
      billingTypes.filter((b) => b.value !== value)
    )
  }

  const filterDataByServices = (data = [], services) => {
    return data.filter((d) => services.includes(d.service))
  }

  const filterDataByBillingTypes = (data = [], types) => {
    return data.filter((d) => types.includes(d.type))
  }
  //helper function for object mapping
  const objectMap = (obj, fn) =>
    Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]))

  const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'Juny',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const getSum = (array) => array.reduce((acc, value) => acc + Number(value), 0)

  const filteredServiceAndBilling = (report) =>
    selectedServices.map((option) => option.value).includes(report.service) &&
    selectedBilling.map((option) => option.value).includes(report.type)

  const yearFilteredDataBySite = (data) => {
    return data.map((site) => ({
      ...site,
      reports: site.reports.filter((report) => report.year === chosenYear),
    }))
  }

  const monthlyCostsDataBySite = yearFilteredDataBySite(
    costsDataBySite(costs.data)
  )
    .map((site) => {
      const reportsByMonth = site.reports.reduce((acc, report) => {
        const month = MONTHS[report.month - 1]
        return filteredServiceAndBilling(report)
          ? {
              ...acc,
              [month]: [...(acc[month] || []), report.cost],
            }
          : acc
      }, {})
      const costSumsByMonth = objectMap(reportsByMonth, getSum)
      if (site.reports.length > 0) {
        const siteDetails = site.reports[0]
        return {
          ...costSumsByMonth,
          site: site.site_name,
          siteDetails: {
            address: {
              city: siteDetails?.city,
              street_name: siteDetails?.street,
            },
          },
        }
      } else {
        return {
          ...costSumsByMonth,
          site: site.site_name,
          siteDetails: { address: { city: '', street_name: '' } },
        }
      }
    })
    .filter((site) => Object.keys(site).length > 2)

  monthlyCostsDataBySite.sort(siteCustomSort)

  const tableColumns = [
    {
      Header: t('Site'),
      accessor: 'site',
      disableFilters: true,
      Cell: (row) => <div style={{ fontSize: '1rem' }}>{row.value}</div>,
      sortType: (a, b) => siteCustomSort(a.original, b.original),
    },
    ...MONTHS.map((month) => ({
      Header: t(month),
      accessor: `${month}`,
      disableFilters: true,
      Cell: (row) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            fontSize: '1rem',
            padding: '16px 0px 16px 4px',
          }}
        >
          {row.value ? `${Math.round(row.value)} €` : null}
        </div>
      ),
    })),
  ]

  const CostsTableHeader = ({ years, chosenYear, setChosenYear }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2 className="title is-size-4">Kustannusjakauma</h2>
        <YearSelection
          years={years}
          chosenYear={chosenYear}
          setChosenYear={setChosenYear}
        />
      </div>
    )
  }

  const customBillingSort = (a, b) => {
    const priority = {
      Sopimustyö: 1,
      Lisätyö: 2,
      Tarvikkeet: 3,
    }

    const aPriority = priority[a.label] || Infinity // Default to Infinity if not in priority list
    const bPriority = priority[b.label] || Infinity

    // Compare based on priority
    return aPriority - bPriority
  }

  return (
    <div className="content">
      <CostsTableHeader
        years={years}
        chosenYear={chosenYear}
        setChosenYear={setChosenYear}
      />
      <div className="columns">
        <div className="column is-one-third">
          <MultiCheckbox
            label={'Palvelut'}
            options={serviceOptions}
            onChange={handleSelectedServiceChange}
            handleDelete={handleDeleteServiceClick}
          />
        </div>
        <div className="column is-one-third">
          <MultiCheckbox
            label={'Laskutustyyppi'}
            options={billingTypeOptions.sort(customBillingSort)}
            onChange={handleSelectedBillingChange}
            handleDelete={handleDeleteBillingTypeClick}
          />
        </div>
      </div>
      <div className="calendar-tracking-table">
        <SortableTable
          columns={tableColumns}
          data={monthlyCostsDataBySite}
          showBottomRowStats={false}
          initialState={{
            sortBy: [
              {
                id: 'site',
                desc: false, // Set to true if you want descending order initially
              },
            ],
          }}
          disableSortRemove={true} //removes unsorted state from table columns
        />
      </div>
    </div>
  )
}

export default CostsTable
